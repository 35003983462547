import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import { List, Timeline, Collapse, Alert } from 'antd';
import { CloseCircleOutlined, LoadingOutlined, ClockCircleOutlined, CheckOutlined } from '@ant-design/icons';

import ProgressBarFake from 'components/ProgressBarFake';

class ServiceTasks extends React.Component {
  static propTypes = {
    tasks: PropTypes.array.isRequired
  };

  state = {
    tasksSorted: [],
    collapsesStatus: {},
    progressBar: {}
  };

  componentDidMount() {
    this.sort(this.props.tasks);
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.tasks, this.props.tasks)) {
      return;
    }
    this.sort(this.props.tasks);
  }

  tasksTypes = {
    serviceCreation: [ 'Service created', 'Creating the service' ],
    serviceConfiguration: [ 'Service configured', 'Configuring the service' ],
    serviceDestruction: [ 'Service deleted', 'Deleting the service' ],
    serviceUpdate: [ 'Service updated', 'Updating the service' ],
    serviceBackup: [ 'Service backuped', 'Backuping the service' ],
    serviceRestart: [ 'Service restarted', 'Restarting the service' ],

    hostMigration: [ 'Migrated to a new host', 'Migrating to a new host' ],
    gatewayMigration: [ 'Migrated to a new gateway', 'Migrating to a new gateway' ],

    instanceRestart: [ 'Instance restarted', 'Restarting the instance' ],
    instanceFirewallUpdate: [ 'Firewall updated', 'Updating firewall' ],
    instanceLogsServerUpdate: [ 'Logs forwarder configured', 'Configuring logs forwader' ],
    instanceUpgrade: [ 'Instance upgraded', 'Upgrading instance' ],
    serviceSuspend: [ 'Service suspended', 'Suspending service' ],
    serviceUnsuspend: [ 'Service unsuspended', 'Unsuspending service' ],
    backupRollback: [ 'Rollbacked to a backup', 'Rolling back to a backup' ],

    // Old
    serviceMigration: [ 'Service migrated', 'Migrating the service' ],
  };

  subTasksTypes = {
    hostnameAndNetworkAssign: [ 'Hostname and IP assigned', 'Assigning a hostname and an IP' ],
    hostnameAndNetworkSharedAssign: [ 'Hostname and IP assigned', 'Assigning a hostname and an IP' ],

    waitForDnsUpdate: [ 'DNS updated', 'Waiting for DNS update' ],

    publicIpAttach: [ 'Dedicated IP configured', 'Configuring the dedicated IP' ],

    hostnameConfigure: [ 'Hostname configured', 'Configuring the hostname' ],
    hostnameDeconfigure: [ 'Hostname deconfigured', 'Deconfiguring the hostname' ],

    vmCreate: [ 'VM created', 'Creating the VM' ],
    vmDestroy: [ 'VM deleted', 'Deleting the VM' ],
    vmStart: [ 'VM started', 'Starting the VM' ],
    vmStartPaused: [ 'VM started in pause', 'Starting the VM in pause' ],
    vmStop: [ 'VM stopped', 'Stopping the VM' ],
    instanceHasStart: [ 'VM start confirmed', 'Waiting for VM to start' ],
    vmConfigurationUpdate: [ 'VM configuration updated', 'Updating VM configuration' ],
    vmUpdateDownload: [ 'VM image downloaded', 'Downloading the update image' ],
    vmUpdateImageReplace: [ 'VM image updated', 'Updating the VM image' ],
    instanceFirewallUpdate: [ 'Firewall updated', 'Updating firewall' ],
    instanceLogsServerConfiguration: [ 'Logs forwarder configured', 'Configuring logs forwader' ],
    vmDisksUpgrade: [ 'VM disks upgraded', 'Upgrading disks VM' ],
    vmDisksDestroy: [ 'Disks deleted', 'Deleting disks' ],
    vmDiskRollback: [ 'Disk rollbacked', 'Rolling back disk' ],
    vmAgentConfiguration: [ 'VM agent configurated', 'VM agent configuration' ],
    vmBackupCreate: [ 'Backup created', 'Creating a backup' ],
    vmBackupWaitForArchive: [ 'Backups archived', 'Archiving backups' ],
    vmIpSharedPortsMap: [ 'IP mapping created', 'Creating IP mapping' ],

    gatewayAssign: [ 'Gateway assigned', 'Assigning a gateway' ],
    gatewayUnassign: [ 'Gateway unassigned', 'Unassigning a gateway' ],
    gatewayNetworkConfigure: [ 'Gateway network configured', 'Configuring gateway network' ],
    gatewayNetworkDeconfigure: [ 'Gateway network deconfigured', 'Deconfiguring gateway network' ],

    hostAssign: [ 'Host assigned', 'Assigning a host' ],
    hostNetworkConfigure: [ 'Host network configured', 'Configuring host network' ],
    hostNetworkDeconfigure: [ 'Host network deconfigured', 'Deconfiguring host network' ],
    hostIpSharedPortMappingConfigure: [ 'IP mapping configured', 'Configuring IP mapping' ],
    hostIpSharedPortMappingDeconfigure: [ 'IP mapping deconfigured', 'Deconfiguring IP mapping' ],
    hostnameAssign: [ 'Hostname assigned', 'Assigning a hostname' ],
    hostnameUnassign: [ 'Hostname unassigned', 'Unassigning a hostname' ],
    hostSourceTunnelConfigure: [ 'Tunnel configured on source', 'Configuring tunnel on source' ],
    hostSourceTunnelDeconfigure: [ 'Tunnel deconfigured on source', 'Deconfiguring tunnel on source' ],
    hostDestinationTunnelConfigure: [ 'Tunnel configured on destination', 'Configuring tunnel on destination' ],
    hostDestinationTunnelDeconfigure: [ 'Tunnel deconfigured on destination', 'Deconfiguring tunnel on destination' ],

    networkAssign: [ 'Network assigned', 'Assigning a network' ],
    networkUnassign: [ 'Network unassigned', 'Unassigning its network' ],

    serviceConfiguration: [ 'Service\'s components configured', 'Configuring service\'s components' ],
    serviceConfigurationDefault: [ 'Service\'s components pre configured', 'Pre configuring service\'s components' ],
    servicePackConfigurationDefault: [ 'Service\'s components pre configured', 'Pre configuring service\'s components' ],
    serviceConfigurationRollback: [ 'Configuration rollbacked', 'Rolling back configuration' ],
    serviceMigration: [ 'Service configuration migrated', 'Migrating service configuration' ],
    serviceStoreUpdateConfiguration: [ 'Service store update configured', 'Configuring service store update' ],

    serviceRestart: [ 'Service restarted', 'Restarting the service' ],
    serviceDelete: [ 'Service deleted', 'Deleting the service' ],

    serviceHostUpdate: [ 'Service host information updated', 'Updating service host information' ],
    serviceIpUpdate: [ 'Service IP information updated', 'Updating service IP information' ],
    serviceGatewayUpdate: [ 'Service gateway information updated', 'Updating service gateway information' ],
    serviceIcmpCheck: [ 'ICMP (ping) reply checked', 'Checking ICMP (ping) reply' ],

    herokuConfigurationUpdate: [ 'Configuration updated on Heroku', 'Updating configuration on Heroku' ],
    herokuProvision: [ 'Provisioned on Heroku', 'Provisioning on Heroku' ],

    billingStart: [ 'Billing started', 'Starting billing' ],
    billingStop: [ 'Billing stopped', 'Stopping billing' ],

    migrationSrcConfigurationGet: [ 'Source configuration gotten', 'Getting source configuration' ],
    migrationSrcMigrationStart: [ 'Service migration start', 'Starting service migration' ],
    migrationSrcMigrationCheckPreCopy: [ 'Migration phase 1 done', 'Waiting for migration phase 1' ],
    migrationSrcMigrationCheckPostCopy: [ 'Migration phase 2 done', 'Waiting for migration phase 2' ],

    migrationDstPrepareVm: [ 'VM prepared on destination', 'Preparing VM on destination' ],
    migrationDstOpenNetwork: [ 'Network opened on destination', 'Opening network on destination' ],
    migrationDstCloseNetwork: [ 'Network closed on destination', 'Closing network on destination' ],
    migrationDstAfterMigrationDone: [ 'Updated configuration on destination', 'Updating configuration on destination' ],

    migrationApiConfigurationCopy: [ 'Configuration copied', 'Copying configuration' ],

    // Old ones
    hostnameConfiguration: [ 'Hostname configured', 'Configuring the hostname' ],
    instancePostInstallation: [ 'VM configured', 'Configuring the VM' ],
    serviceInstallation: [ 'Service installed', 'Installing the service' ],

    bmAssign: [ 'Host assigned', 'Assigning a host' ],
    serviceBmUpdate: [ 'Service host information updated', 'Updating service host information' ],

    networkConfiguration: [ 'Network configured', 'Configuring the network' ],
    networkReconfigure: [ 'Network reconfigured', 'Reconfiguring the network' ],
    networkUpdate: [ 'Network updated', 'Updating the network' ],
    networkDeconfiguration: [ 'Network deconfigured', 'Deconfiguring the network' ],
    hostnameDeconfiguration: [ 'Hostname deconfigured', 'Deconfiguring the hostname' ],
    hostnameAndNetworkUnassign: [ 'Hostname and IP unassigned', 'Unassigning a hostname and an IP' ],
    bmUnassign: [ 'Server unassigned', 'Unassigning its server'],

    instanceStart: [ 'VM started', 'Starting the VM' ],
    instanceStartPaused: [ 'VM started in pause', 'Starting the VM in pause' ],
    instanceStop: [ 'VM stopped', 'Stopping the VM' ],

    instanceConfigurationUpdate: [ 'VM configuration updated', 'Updating VM configuration' ],
    instanceUpdateDownload: [ 'VM image downloaded', 'Downloading the update image' ],
    instanceUpdateImageReplace: [ 'VM image updated', 'Updating the VM image' ],
    instanceDisksUpgrade: [ 'VM disks upgraded', 'Upgrading disks VM' ],
    instanceDisksDestroy: [ 'Disks deleted', 'Deleting disks' ],
    instanceDiskRollback: [ 'Disk rollbacked', 'Rolling back disk' ],
    instanceAgentConfiguration: [ 'VM agent configurated', 'VM agent configuration' ],

    instanceBackupCreate: [ 'Backup created', 'Creating a backup' ],
    instanceBackupWaitForArchive: [ 'Backups archived', 'Archiving backups' ],

    serviceDestroy: [ 'Service deleted', 'Deleting the service' ]
  };


  sort(tasks) {
    // We copy the array because sort overwride the array
    const tasksSorted = [ ...tasks ];
    tasksSorted
      .sort((a, b) => new Date(b.addedDate).getTime() - new Date(a.addedDate).getTime()); // Sort tasks per date (desc)
    this.setState({ tasksSorted });
  }


  getColor(status) {
    const colors = {
      error: 'red',
      done: 'green',
      todo: '#bfbfbf'
    };
    return colors[status] ? colors[status] : 'blue';
  }


  getDot(status) {
    const icons = {
      error: CloseCircleOutlined,
      doing: LoadingOutlined,
      todo: ClockCircleOutlined,
      done: CheckOutlined
    };
    const Icon = icons[status];
    return Icon ? ( <Icon style={{ fontSize: 16 }} /> ) : null;
  }


  formatDate(date) {
    const dateOption = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Intl.DateTimeFormat(undefined, dateOption).format(new Date(date));
  }


  renderSubTask(previousSubTask, subTask, index) {
    const doingDate = previousSubTask ? previousSubTask.doneDate : subTask.doingDate ? subTask.doingDate : 0;

    const timing = subTask.doneDate ? Math.round((new Date(subTask.doneDate) - new Date(doingDate)) / 1000) || 1 : null;
    const timingSentence = timing === null ? null : (
      <div style={{ color: '#8c8c8c', marginLeft: 15 }}>
        {timing <= 1 ? 'Done in a second.' : null }
        {timing > 1 && timing <= 120 ? `Done in ${timing} seconds.` : null }
        {timing > 120 ? `Done in ${Math.round(timing / 60)} minutes.` : null }
      </div>
    );

    const errorSentence = subTask.status !== 'error' ? null : (
      <div style={{ color: '#ff4d4f', marginLeft: 15 }}>
        An error occured. Our team has been warned and will resolve this issue soon.
      </div>
    );

    const status = subTask.status === 'todo' && (index === 0 || previousSubTask.status === 'done') ? 'doing' : subTask.status;
    const progress = subTask.status === 'doing' ? ( <ProgressBarFake /> ) : null;

    if (!this.subTasksTypes[subTask.type]) {
      return ( <Alert key={index} type="error" message={`Error: unknown subTask ${subTask.type}`} /> );
    }

    return {
      color: this.getColor(subTask.status),
      dot: this.getDot(status),
      children: (
        <>
          {this.subTasksTypes[subTask.type][subTask.status === 'done' ? 0 : 1]}
          {progress || errorSentence || timingSentence || (<div>&nbsp;</div>)}
        </>
      )
    };
  }


  renderSubTasks(task) {
    return (
      <Timeline
        style={{ marginTop: 20, marginLeft: 20 }}
        items={task.subTasks.map(
          (subTask, index, subTasks) => this.renderSubTask(index ? task.subTasks[index - 1] : null, subTask, index
        ))}
      />
    );
  }


  render() {
    return (
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={this.state.tasksSorted}
        renderItem={(task, index) => {
          const addedDate = this.formatDate(task.addedDate);
          const key = addedDate + task.type;
          const activeKey = task.status !== 'done' ? '1' : this.state.collapsesStatus[key];
          const onChange = (key, value) => {
            this.setState(state => {
              return {
                ...state,
                collapsesStatus: {
                  ...state.collapsesStatus,
                  [key]: value
                }
              };
            });
          };

          if (!this.tasksTypes[task.type]) {
            return ( <Alert key={index} type="error" message={`Error: unknown task ${task.type}`} /> );
          }
          return (
            <Collapse
              key={index}
              bordered={false}
              activeKey={activeKey}
              onChange={onChange.bind(this, key)}
              style={{ backgroundColor: 'transparent' }}
              items={[
                {
                  key: 1,
                  label: `${addedDate} - ${this.tasksTypes[task.type][task.status === 'done' ? 0 : 1]}`,
                  children: (
                    <List.Item style={{ padding: 0 }}>
                      <List.Item.Meta description={this.renderSubTasks(task)} />
                    </List.Item>
                  )
                }
              ]}
            />
          );
        }}
      />
    );
  }
}

export default ServiceTasks;
